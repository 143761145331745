import React, { useEffect, useRef, useCallback } from "react";
import Contact from "../components/Contact";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { PheonixProps } from "../interfaces/HomeProps";
import About from "../components/About";
import Product from "../components/Product";
import Features from "../components/Features";
import Faq from "../components/Faq";
import Template from "../components/Template";
import Feedback from "../components/Feedback";
import Banner from "../components/Banner";
import BuildBanner from "../components/imgbanner";
import Grants from "../components/Grants";


const Home: React.FC<PheonixProps> = ({ sections }) => {
  const contactRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []); 
  
  const scrollToContact = useCallback(() => {
    if (contactRef.current) {
      const headerElement = document.querySelector("header");
      const headerOffset = headerElement ? headerElement.offsetHeight : 0;
  
      const elementPosition =
        contactRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
  
      contactRef.current.setAttribute("tabindex", "-1");
      contactRef.current.focus({ preventScroll: true });
    }
  }, []);

  return (
    <div
      style={{
        textAlign: "center",
      }}
    >     
      <About/>
      <Banner />
      <BuildBanner />
      <Template sections={sections}  onEnquiryClick={scrollToContact} />
      <Product sections={sections} />
      <Features sections={sections} />
      <Feedback sections={sections} />
      <div ref={contactRef}>
      <Contact sections={sections} />
      </div>
      <Faq sections={sections} />
      <Grants sections={sections} />
    </div>
   
  );
};
export default Home;
