import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import data from "../data/data.json";
import { PheonixProps } from "../interfaces/HomeProps";
import themestyle from "../theme";
import GrantCard from "./GrantCard";
import fund from "../assets/fund.png";
import hands from "../assets/hands.png";
import flag from "../assets/flag.png";
import Euro from "../assets/Euro.png";
import bro from "../assets/bro.png";
import { TEXT_MESSAGES } from "../const";
import ContactModal from "./ContactModal";

const imageMap: { [key: string]: string } = { fund, hands };

const Grants: React.FC<PheonixProps> = ({ sections }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLaptopSmall = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const [modalOpen, setModalOpen] = useState(false);
  const handleActionClick = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <Box
      ref={sections.grantsRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        marginTop: "24px",
        padding: isMobile ? "16px" : "32px",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontSize: isMobile ? "32px" : "36px",
          fontWeight: 600,
          fontFamily: "Lexend",
          color: "#101828",
          marginBottom: "20px",
        }}
      >
        {data.grants.GRANTS}
      </Typography>

      <Typography
        variant="body1"
        sx={{
          fontSize: isMobile ? "18px" : "24px",
          fontWeight: 400,
          textAlign: "center",
          lineHeight: "32px",
          fontFamily: "Lexend",
          color: "#263238",
          marginBottom: "30px",
        }}
      >
        {data.grants.GRANTS_TEXT}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "10px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            flex: 1,
            padding: { xs: "0", sm: "20px" },
            borderRadius: "8px",
            textAlign: "left",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <img
              src={flag}
              alt={""}
              style={{ width: "35px", height: "39px" }}
            />
            <Typography
              sx={{
                fontFamily: "Lexend",
                fontSize: "28px",
                fontWeight: 400,
                fontColor: "#000000",
                mb: 2,
                ml: 1,
              }}
            >
              {TEXT_MESSAGES.GRANTIRELAND}
            </Typography>
          </Box>
          <Box sx={{ backgroundColor: "#f4f4f4", padding: "20px" }}>
            <Typography
              sx={{
                fontFamily: "Lexend",
                fontSize: "16px",
                fontWeight: 600,
                color: "#000000",
              }}
              mt={0.5}
            >
              {data.grants.GRANTS_TEXT_TITLE}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Lexend",
                fontSize: "18px",
                fontWeight: 400,
                color: "#000000",
                lineHeight: "22.5px",
              }}
              mt={2}
            >
              {data.grants.ENTERPRISE_GRANTS_TEXT}
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", marginTop: 1 }}
            >
              <Button
               onClick={() => window.open("https://www.enterprise-ireland.com/en/supports/funding-and-grants", "_blank")}
                sx={{
                  color: themestyle.colors.white,
                  textTransform: "none",
                  fontWeight: 600,
                  fontFamily: "Poppins",
                  fontSize: "14px !important",
                  borderRadius: "4px",
                  width: "117px",
                  height: "32px",
                  background:
                    "linear-gradient(90deg, #44C597 0%, #0177BF 100%)",
                  "&:hover": {
                    color: "#0177BF",
                    background: themestyle.colors.white,
                  },
                }}
              >
                {TEXT_MESSAGES.MOREDETAIL}
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: isMobile || isTablet ? "10px" : "30px",
          }}
        >
          {data.grants.CARDS.map((card, index) => (
            <GrantCard
              key={index}
              title={
                card.title === "Who Is It ?" ? (
                  <>
                    {TEXT_MESSAGES.WHOISIT}{" "}
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: "28px",
                        color: "#000",
                      }}
                    >
                      ?
                    </span>{" "}
                  </>
                ) : (
                  card.title
                )
              }
              image={imageMap[card.image ? card.image : ""]}
              description={
                card.title === "Funding Amount" ? (
                  <>
                    {TEXT_MESSAGES.UPTO}{" "}
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: "24px",
                        color: "#000",
                      }}
                    >
                      {TEXT_MESSAGES.EURO}
                    </span>{" "}
                    {TEXT_MESSAGES.COFUND}{" "}
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: "24px",
                        color: "#000",
                      }}
                    >
                      {TEXT_MESSAGES.PERCENT}
                    </span>
                  </>
                ) : (
                  card.description
                )
              }
              width={card.width}
              height={card.height}
            />
          ))}
        </Box>

        <Box sx={{ flex: 1, mt: 3 }}>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 1, lg: 3 }}
            direction={isLaptopSmall ? "column" : "row"}
            alignItems="center"
          >
            <Grid item xs={12} md={4}>
              <img
                src={bro}
                alt="bro"
                style={{
                  marginLeft: isMobile ? "30px" : "10px",
                  marginTop: isTablet || isMobile || isLaptopSmall ? 0 : "20px",
                  height: isTablet
                    ? "140px"
                    : isLaptopSmall
                    ? "120px"
                    : "170px",
                  width: isTablet ? "172px" : "192px",
                  maxWidth: "200px",
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={8}
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: isMobile
                    ? "left"
                    : isLaptopSmall || isTablet
                    ? "center"
                    : "flex-end",
                  marginLeft: isMobile ? "20px" : 0,
                  width: "100%",
                }}
              >
                <Button
                  onClick={handleActionClick}
                  sx={{
                    color: themestyle.colors.white,
                    textTransform: "none",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    fontSize: "18px !important",
                    borderRadius: "100px",
                    width: "133px",
                    height: "32px",
                    background:
                      "linear-gradient(90deg, #44C597 0%, #0177BF 100%)",
                    "&:hover": {
                      color: "#0177BF",
                      background: themestyle.colors.white,
                    },
                  }}
                >
                  {TEXT_MESSAGES.CONTACTUS}
                </Button>
              </Box>

              <Box
                sx={{
                  textAlign: "left",
                  mt: { xs: 2, sm: 2, md: 1, lg: 8 },
                  marginLeft:
                    isMobile || isTablet || isLaptopSmall ? "20px" : "60px",
                  fontFamily: "Lexend",
                  fontSize: { xs: "12px", sm: "14px", md: "14px", lg: "20px" },
                  fontWeight: 600,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "14px",
                      lg: "20px",
                    },
                    fontWeight: 600,
                  }}
                >
                  {TEXT_MESSAGES.DONTWORRY}
                </Typography>
                {isLaptopSmall ? (
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: {
                        xs: "14px",
                        sm: "14px",
                        md: "14px",
                        lg: "20px",
                      },
                      fontWeight: 600,
                    }}
                  >
                    {TEXT_MESSAGES.HELP}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: {
                        xs: "14px",
                        sm: "14px",
                        md: "14px",
                        lg: "20px",
                      },
                      fontWeight: 600,
                    }}
                  >
                    {TEXT_MESSAGES.HELPMAIN} {<br />} {TEXT_MESSAGES.HELPSUB}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={12} sx={{ paddingTop: 0 }}>
              <Box
                sx={{
                  padding: isTablet || isLaptopSmall ? "5px" : "15px",
                  borderRadius: "10px",
                  backgroundColor: "#F2F2F2",
                  position: "relative",
                  mt: { xs: 1, sm: 1, md: 0, lg: 2 },
                  width:
                    isMobile || isTablet || isLaptopSmall ? "100%" : "auto",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontFamily: "Lexend",
                      color: "#263538",
                      fontSize: isTablet || isLaptopSmall ? "14px" : "18px",
                      textAlign: "left",
                    }}
                  >
                    {TEXT_MESSAGES.FUNDRAISE}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 400,
                      fontFamily: "Lexend",
                      fontSize: isTablet || isLaptopSmall ? "12px" : "14px",
                      color: "#263538",
                      marginTop: isTablet || isLaptopSmall ? "2px" : "12px",
                      textAlign: "left",
                      lineHeight: "17.5px",
                    }}
                  >
                    {TEXT_MESSAGES.SCHEME} {<br />} {TEXT_MESSAGES.SCHEMESUB}
                  </Typography>
                </Box>
                <img
                  src={Euro}
                  alt="euro"
                  style={{ width: "42px", height: "47px", marginTop: "50px" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ContactModal open={modalOpen} onClose={handleCloseModal} />
    </Box>
  );
};

export default Grants;
