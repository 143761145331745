import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import themestyle from "../theme";

interface GrantCardProps {
  title: React.ReactNode;
  image?: string;
  description: React.ReactNode;
  width?: string;
  height?: string;
}

const GrantCard: React.FC<GrantCardProps> = ({
  title,
  image,
  description,
  width,
  height,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <Box
      sx={{
        background: `linear-gradient(90deg, ${themestyle.colors.bgprimary} 0%, ${themestyle.colors.bgsecondary} 100%)`,
        padding: "10px 15px",
        borderTopRightRadius: "15px",
        borderBottomLeftRadius: "0px",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: "10px",
        width: isMobile ? "100%" : isTablet ? "100%" : width || "auto",
        height: isMobile ? "auto" : isTablet ? "auto" : height || "auto",
        minWidth: "200px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography
          sx={{
            fontFamily: "Lexend",
            fontSize: isMobile ? "16px" : "18px",
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        {image && (
          <img
            src={image}
            alt=""
            style={{
              width: isMobile ? "20px" : "24px",
              height: isMobile ? "18px" : "20px",
            }}
          />
        )}
      </Box>

      <Typography
        sx={{
          fontFamily: "Lexend",
          fontSize: isMobile ? "14px" : "16px",
          fontWeight: 400,
          color: "#000000",
          lineHeight: "16px",
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default GrantCard;
