import React, { ReactNode, useState } from 'react';
import Navbar from './PheonixNavbar';
import Footer from './PheonixFooter';
import ScrollToTopButton from "./PheonixScroll";

interface LayoutProps {
  children: ReactNode;
  sections: {
    aboutRef: any;
    templateRef: React.RefObject<HTMLDivElement>;
    contactRef: React.RefObject<HTMLDivElement>;
    testimonialRef: React.RefObject<HTMLDivElement>;
    featureRef: React.RefObject<HTMLDivElement>;
    grantsRef: React.RefObject<HTMLDivElement>;
  };
}

const PheonixLayout: React.FC<LayoutProps> = ({ children, sections }) => {
  const [activeTab, setActiveTab] = useState<number>(0); 
  const resetActiveTab = () => {
    setActiveTab(0);
  };
return (
    <div>
      <Navbar sections={sections} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child);
          }
          return child;
        })}
      </div>
      <Footer />
      <ScrollToTopButton resetActiveTab={resetActiveTab} />
    </div>
  );
};
export default PheonixLayout;